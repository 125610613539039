import React from 'react';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import taSound from './ta.mp3'
import mateSound from './mate.mp3'
import tamateSound from './tamate.mp3'
import backSound from './background.mp3'
import backLoop from './backgroundLoop.mp3'

import goSound from './gameover.mp3'

import './App.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    height: '100%',
    width: '100%'
  },
  appBackground: {
    // background: '#35654d',
    // background: 'radial-gradient(circle, #35654d 35%, #000 100%)',
  },
  blockCenter: {
    height: '1px',
    width: '1px',
    border: '1px solid white',
  },

  taBlock: {
    // padding: 0,
  },
  mateBlock: {
    // padding: 0,
  },
  tamateBlock: {
    padding: 0,
  },

  mainLightContainer: {
    height: '80px',
    width: '80px',
    position: 'absolute',
    border: '2px solid #fff',
    borderRadius: '50%',
    boxShadow: '0 0px 20px #bf00ff,0 0 0.5em #bf00ff, inset 0 0 0.5em #bf00ff, 0 0 0.25em #bf00ff, inset 0 0 0.25em #bf00ff, 0 0 0.1em #bf00ff, inset 0 0 0.1em #bf00ff',
  },
  mainLightContainerInner: {
    height: '62px',
    width: '62px',
    position: 'absolute',
    border: '2px solid #fff',
    borderRadius: '100%',
    boxShadow: '0 0px 20px #21fc0d,0 0 0.3em #21fc0d, inset 0 0 0.1em #21fc0d, 0 0 0.1em #21fc0d, inset 0 0 0.5em #21fc0d, 0 0 0.1em #21fc0d, inset 0 0 0.1em #21fc0d',
  },
  taLightContainer: {
    height: '100px',
    width: '100px',
    position: 'absolute',
    border: '5px solid #fef',
    borderRadius: '100%',
    boxShadow: '0 0 50px #ff0490, inset 0 0 25px #ff0490, 0 0 2px, inset 0 0 2px, 0 0 1em #ff0490, inset 0 0 1em #ff0490, 0 0 0.5em #ff0490, inset 0 0 0.5em #ff0490, 0 0 0.1em #ff0490, inset 0 0 0.1em #ff0490, 0 10px 3px #000',
  },
  mateLightContainer: {
    height: '110px',
    width: '110px',
    position: 'absolute',
    border: '5px solid #fee',
    borderRadius: '25%',
    align: 'center',
    boxShadow: '0 0 50px #21fc0d, inset 0 0 25px #21fc0d, 0 0 2px, inset 0 0 2px, 0 0 1em #21fc0d, inset 0 0 1em #21fc0d, 0 0 0.5em #21fc0d, inset 0 0 0.5em #21fc0d, 0 0 0.1em #21fc0d, inset 0 0 0.1em #21fc0d, 0 10px 3px #000',
  },
  tamateLightContainer: {
    height: '100px',
    width: '200px',
    position: 'absolute',
    border: '5px solid #efe',
    borderRadius: '100%',
    align: 'center',
    boxShadow: '0 0px 50px #bf00ff, inset 0 0 25px #bf00ff, 0 0 2px, inset 0 0 2px, 0 0 1em #bf00ff, inset 0 0 1em #bf00ff, 0 0 0.5em #bf00ff, inset 0 0 0.5em #bf00ff, 0 0 0.1em #bf00ff, inset 0 0 0.1em #bf00ff, 0 10px 3px #000',
  },

  textStyleMain: {
    position: 'absolute',
    transform: 'translate(-50%,-55%) rotate(-20deg)',
    top: '50%',
    left: '50%',
    font: '400 3em Vibur',
    color: '#fff',
    textShadow: '0 0 20px #ff0490,0 0 30px #ff0490,0 0 20px #ff0490, 0 0 10px #ff0490,0 0 2px #ff0490, 0 0 1em #ff0490, 0 0 0.5em #ff0490, 0 0 0.1em #ff0490, 0 10px 3px #000',
  },
  textFlicker2s: {
    animation: 'blink linear infinite 2s',
  },
  textFlicker3s: {
    animation: 'blink linear infinite 3s',
  },

  textStyleTa: {
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    top: '50%',
    left: '50%',
    font: '300 125px Vibur',
    color: '#ff0490',
    textShadow: '0 0 100px #ff0490, 0 0 2px #ff0490, 0 0 1em #ff0490, 0 0 0.5em #ff0490, 0 0 0.1em #ff0490, 0 10px 3px #000',
  },
  textStyleMate: {
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    top: '50%',
    left: '50%',
    font: '400 100px Vibur',
    color: '#21fc0d',
    textShadow: '0 0 100px #21fc0d, 0 0 2px #21fc0d, 0 0 1em #21fc0d, 0 0 0.5em #21fc0d, 0 0 0.1em #21fc0d, 0 10px 3px #000',
  },
  textStyleTamate: {
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    top: '50%',
    left: '50%',
    font: '400 100px Vibur',
    color: '#bf00ff',
    textShadow: '0 0 80px #bf00ff, 0 0 2px #bf00ff, 0 0 1em #bf00ff, 0 0 0.5em #bf00ff, 0 0 0.1em #bf00ff, 0 10px 3px #000',
  },
  startButtonContainer: {
    padding: '0px',
    height: '100px',
  },
  startButtonFont: {
    [theme.breakpoints.down('sm')]: {
      font: '800 1.4em neon-tubes-2-regular',
    },
    [theme.breakpoints.up('sm')]: {
      font: '800 1.6em neon-tubes-2-regular',
    },
    color: '#fff',
    textShadow: '0 0 10px #fff, 0 0 20px #fff,0 0 30px #fff, 0 0 5px #fff, 0 0 2px #333,0 0 1em #fff, 0 0 0.5em #fff, 0 0 0.1em #fff, 0 10px 3px #000',
  },
  topScoreBlock: {
    padding: '0px',
    width: '100%',
  },
  yourScoreBlock: {
    padding: '0px',
    width: '100%',
  },
  topScoreTextFont: {
    [theme.breakpoints.up('xs')]: {
      font: '800 12px neon-tubes-2-regular',
    },
    [theme.breakpoints.up('md')]: {
      font: '800 14px neon-tubes-2-regular',
    },
    color: '#fff',
    textShadow: '0 0px 10px #ff000d, 0 0px 20px #ff000d,0 0px 30px #ff000d,0 0 40px #ff000d, 0 0 1em #ff000d, 0 0 0.5em #ff000d, 0 0 0.1em #ff000d, 0 10px 3px #000',
  },
  topScoreFont: {
    [theme.breakpoints.up('xs')]: {
      font: '100 1.8em neon-tubes-2-regular',
    },
    [theme.breakpoints.up('sm')]: {
      font: '800 2em neon-tubes-2-regular',
    },
    color: '#fff',
    textShadow: '0 0px 10px #ff000d, 0 0px 20px #ff000d,0 0px 30px #ff000d,0 0 40px #ff000d, 0 0 1em #ff000d, 0 0 0.5em #ff000d, 0 0 0.1em #ff000d, 0 10px 3px #000',
  },

  yourScoreTextFont: {
    [theme.breakpoints.down('sm')]: {
      font: '800 0.9em neon-tubes-2-regular',
    },
    [theme.breakpoints.up('sm')]: {
      font: '800 1em neon-tubes-2-regular',
    },
    color: '#fff',
    textShadow: '0 0px 10px #60f, 0 0px 20px #60f,0 0px 30px #60f,0 0 40px #60f, 0 0 1em #60f, 0 0 0.5em #60f, 0 0 0.1em #60f, 0 10px 3px #000',
  },
  yourScoreFont: {
    [theme.breakpoints.down('sm')]: {
      font: '800 2.0em neon-tubes-2-regular',
    },
    [theme.breakpoints.up('sm')]: {
      font: '800 2.2em neon-tubes-2-regular',
    },
    color: '#fff',
    textShadow: '0 0px 10px #60f, 0 0px 20px #60f,0 0px 30px #60f,0 0 40px #60f, 0 0 1em #60f, 0 0 0.5em #60f, 0 0 0.1em #60f, 0 10px 3px #000',
  },

  clickButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    opacity: '0',
    zIndex: '1',
  },
  taButtonRadius: {
    borderRadius: '100%',
  },
  mateButtonRadius: {
    borderRadius: '25%',
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.taRef = React.createRef();
    this.mateRef = React.createRef();
    this.tamateRef = React.createRef();
    this.startRef = React.createRef();

    this.state = {
      gameOn: false,
      score: 0,
      topScore: null,
      soundLoaded: false,
      width: 0,
      height: 0,
      device: '',
      orientation: '',
      topSection: 12,
      bottomSection: 12,
      logoSection: 5,
      scoreSection: 5,
      flicker: false,
    };

    this.toClickTile = 0;
    this.clickTile = 0;
    this.tileClicked = false;
    this.continuePlay = false;
    this.bgMusicStarted = false;
    this.bgLoopStarted = false;
    this.goMusicLoaded = false;
    this.loopMusicLoaded = false;
    this.gameSpeed = 1600;
    this.playbackSpeed = 1;
    this.hintSpeed = 200;

    this.tileAudio = null;
    this.taAudio = null;
    this.mateAudio = null;
    this.tamateAudio = null;
    this.goAudio = null;
    this.backgroundAudio = null;
    this.backgroundLoop = null;

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    axios
      .get("https://8n795h2v68.execute-api.ap-southeast-2.amazonaws.com/tamateScore")
      .then((response) => {
        this.setState({
          topScore: response.data
        })
      })
      .catch(function (error) {
        console.log(error);
      });
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('orientationchange', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('orientationchange', this.updateWindowDimensions);
  }

  isMobile() {
    let check = false;
    (function (a) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  updateWindowDimensions() {
    this.setState({
      width: (window.innerWidth || window.screen.width),
      height: (window.innerHeight || window.screen.height),
      device: this.isMobile() ? 'mobile' : 'desktop',
      orientation: window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape',
    }, () => {
      if (960 > this.state.width > 0 && this.state.orientation === 'landscape' && this.state.device === 'mobile') {
        this.setState({
          topSection: 4,
          bottomSection: 8,
          logoSection: 12,
          scoreSection: 12,
          topRowHeight: '100%',
          bottomRowHeight: '100%',
        });
      }
      else {
        this.setState({
          topSection: 12,
          bottomSection: 12,
          logoSection: false,
          scoreSection: false,
          topRowHeight: '35%',
          bottomRowHeight: '65%',
        });
      }
    });
  }

  postHighScore(s) {
    let body = {
      newHighScore: s.toString(),
    };
    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: 'https://8n795h2v68.execute-api.ap-southeast-2.amazonaws.com/tamateScore',
      data: body
    }).then(function (response) {
      // console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    });
  }

  tileClick(e, val) {
    if (!this.tileClicked) {
      this.tileClicked = true;
      if (this.toClickTile === val) {
        this.setState({
          score: this.state.score + 1,
        }, () => {

        })
        this.continuePlay = true;
      }
      else {
        this.continuePlay = false;
        this.gameOver();
      }
    }
  }

  resetGame() {
    this.setState({
      score: 0,
    })
    this.toClickTile = 0;
    this.clickTile = 0;
    this.tileClicked = false;
    this.continuePlay = false;
    this.gameSpeed = 1600;
    this.playbackSpeed = 1;
    this.hintSpeed = 200;
    this.bgMusicStarted = false;
  }

  startGame(e) {
    // Load Sounds
    if (!this.state.soundLoaded) {
      this.goAudio = new Audio();
      this.goAudio.src = goSound;
      this.goAudio.playbackRate = 1.5;
      this.goAudio.muted = true;
      this.goAudio.play();
      this.goAudio.addEventListener('timeupdate', (e) => {
        if (e.currentTarget.currentTime > 0 && !this.goMusicLoaded) {
          this.goMusicLoaded = true;
          e.currentTarget.pause();
          e.currentTarget.muted = false;
          e.currentTarget.currentTime = 0;
        }
      });

      //Preload other sounds
      this.taAudio = new Audio();
      this.taAudio.src = taSound;

      this.mateAudio = new Audio();
      this.mateAudio.src = mateSound;

      this.tamateAudio = new Audio();
      this.tamateAudio.src = tamateSound;

      this.backgroundAudio = new Audio(backSound);

      this.backgroundLoop = new Audio();
      this.backgroundLoop.src = backLoop;
      this.backgroundLoop.playbackRate = 1;
      this.backgroundLoop.muted = true;
      this.backgroundLoop.play();
      this.backgroundLoop.addEventListener('timeupdate', (e) => {
        var buffer = 0.44;
        if (e.currentTarget.currentTime > 0 && !this.loopMusicLoaded) {
          this.loopMusicLoaded = true;
          e.currentTarget.pause();
          e.currentTarget.muted = false;
          e.currentTarget.currentTime = 0;
        }

        // RE-Loop Sound 
        if (e.currentTarget.currentTime > (e.currentTarget.duration - buffer)) {
          this.backgroundLoop.currentTime = 0;
          this.backgroundLoop.play();
        }
      });
    }

    // Reset State (score, gamespeed, playbackSpeed)
    this.resetGame();

    // Turn off START light
    this.startRef.current.style.color = '#333';

    // Start Background Music
    this.backgroundAudio.playbackRate = 1;
    this.backgroundAudio.currentTime = 0;
    this.backgroundAudio.play();

    // Set Background Music Event Listener
    this.backgroundAudio.addEventListener('timeupdate', (e) => {
      var buffer = 0.33;
      if (e.currentTarget.currentTime > 0 && !this.bgMusicStarted) {

        this.bgMusicStarted = true;
        this.lightsOn();

        // Set timeout to start game.
        setTimeout(() => {
          this.setState({
            gameOn: true,
            flicker: false,
          }, () => {
            this.toClickTile = 1;
            this.playGame(1);
          })
        }, 500);
      }

      if ((e.currentTarget.currentTime > ((e.currentTarget.duration) - buffer)) && !this.bgLoopStarted) {
        this.bgLoopStarted = true;
        this.backgroundLoop.play();
      }
    });

    this.setState({
      soundLoaded: true,
      flicker: true,
    });
  }

  lightsOn() {
    // Turn Lights On
    this.taRef.current.style.color = '#fef';
    this.mateRef.current.style.color = '#fee';
    this.tamateRef.current.style.color = '#efe';
  }

  playGame(val) {
    this.tileHint(val);
    setTimeout(() => {
      if (this.tileClicked) {
        this.tileClicked = false;
        if (this.continuePlay) {
          if (this.state.score % 5 === 0) {
            if (this.state.score <= 90) {
              this.gameSpeed = this.gameSpeed - 50;
              this.playbackSpeed = this.playbackSpeed + 0.05;
              this.hintSpeed = this.hintSpeed - 5;
            }
          }
          this.toClickTile = (Math.floor(Math.random() * 3) + 1);
          this.playGame(this.toClickTile);
        }
      }
      else {
        this.gameOver();
      }
    }, this.gameSpeed);
  }

  tileHint(val) {
    switch (val) {
      case 1:
        this.taRef.current.style.fontSize = '140px';
        this.playSound(val);
        setTimeout(() => {
          this.taRef.current.style.fontSize = '120px';
        }, this.hintSpeed);
        break;
      case 2:
        this.mateRef.current.style.fontSize = '120px';
        this.playSound(val);
        setTimeout(() => {
          this.mateRef.current.style.fontSize = '100px';
        }, this.hintSpeed);
        break;
      case 3:
        this.tamateRef.current.style.fontSize = '120px';
        this.playSound(val);
        setTimeout(() => {
          this.tamateRef.current.style.fontSize = '100px';
        }, this.hintSpeed);
        break;
      default:
        break;
    }
  }

  playSound(val) {
    switch (val) {
      case 1:
        this.taAudio.playbackRate = this.playbackSpeed;
        this.taAudio.currentTime = 0;
        this.taAudio.play();
        break;
      case 2:
        this.mateAudio.playbackRate = this.playbackSpeed;
        this.mateAudio.currentTime = 0;
        this.mateAudio.play();
        break;
      case 3:
        this.tamateAudio.playbackRate = this.playbackSpeed;
        this.tamateAudio.currentTime = 0;
        this.tamateAudio.play();
        break;
      default:
        break;
    }
  }

  gameOver() {
    this.goAudio.currentTime = 0;
    this.goAudio.play();

    //Turn off lights
    this.taRef.current.style.color = '#ff0490';
    this.mateRef.current.style.color = '#21fc0d';
    this.tamateRef.current.style.color = '#bf00ff';

    this.backgroundAudio.pause();
    this.backgroundAudio.currentTime = 0;
    this.backgroundLoop.pause();
    this.backgroundLoop.currentTime = 0;

    this.setState({
      gameOn: false,
    }, () => {
      if (this.state.score > this.state.topScore) {
        this.setState({
          topScore: this.state.score,
        })
        this.postHighScore(this.state.score);
      }
      setTimeout(() => {
        this.startRef.current.style.color = '#fff';
      }, 1000);
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="App">
        <Grid container justify="flex-start" alignItems="center" direction="column" spacing={0} className={classes.root}>
          <Grid item style={{ color: 'white', height: '25%', width: '100%' }}>
          </Grid >
          <Grid item style={{ color: 'white', height: '75%', width: '100%' }}>
            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
              {/* Left Section for Ads */}
              <Hidden mdDown>
                <Grid item xs={false} lg={4} style={{ padding: 0 }}></Grid >
              </Hidden>

              {/* Middle Section with Game */}
              <Grid item lg={4} style={{ height: '100%' }}>
                <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>

                  {/* Top Row with Logo, Score, and Start Button */}
                  <Grid item xs={this.state.topSection} style={{ height: this.state.topRowHeight }} >
                    <Grid container justify="center" alignItems="center">
                      <Grid item xs={this.state.logoSection} style={{ height: '100px', width: '100px' }}>
                        <Grid container justify="center" alignItems="center">
                          <Grid item>
                            <Grid container style={{ height: '100px', width: '100px' }} justify="center" alignItems="center" >
                              <Grid item>
                                <Grid container className={classes.blockCenter} justify="center" alignItems="center" direction="column" spacing={0}>
                                  <Grid item className={classes.mainLightContainer}>
                                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                                      <Grid item>
                                        <Grid container className={classes.blockCenter} justify="center" alignItems="center" direction="column" spacing={0}>
                                          <Grid item className={classes.mainLightContainerInner}>
                                            <div className={classes.textStyleMain}>
                                              <span>tamate</span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid >
                        </Grid>
                      </Grid >
                      <Grid item xs={this.state.scoreSection} style={{ height: '100px', width: '200px' }}>
                        <Grid container justify="center" alignItems="center">
                          <Grid item xs={10} >
                            <Grid container spacing={0} justify="center" alignItems="center" direction="column" >
                              <Grid item style={{ width: '100%' }}>
                                <Grid container className={classes.yourScoreBlock} justify="space-evenly" alignItems="center" >
                                  <Grid item>
                                    <Grid container style={{ height: '100%' }} justify="center" alignItems="center" direction="column" >
                                      <Grid item style={{ height: '100%', width: '100%' }}>
                                        <div className={classes.yourScoreTextFont}>
                                          YOUR
                                    </div>
                                      </Grid>
                                      <Grid item >
                                        <div className={classes.yourScoreTextFont}>
                                          SCORE
                                    </div>
                                      </Grid >
                                    </Grid>
                                  </Grid >
                                  <Grid item >
                                    <div className={classes.yourScoreFont}>
                                      {this.state.score}
                                    </div>
                                  </Grid >
                                </Grid >
                              </Grid >
                              <Grid item style={{ width: '100%' }}>
                                <Grid container className={classes.topScoreBlock} justify="space-evenly" alignItems="center" >
                                  <Grid item>
                                    <Grid container style={{ height: '100%' }} justify="center" alignItems="center" direction="column" >
                                      <Grid item style={{ height: '100%', width: '100%' }}>
                                        <div className={classes.topScoreTextFont}>
                                          TOP
                                    </div>
                                      </Grid>
                                      <Grid item>
                                        <div className={classes.topScoreTextFont}>
                                          SCORE
                                    </div>
                                      </Grid >
                                    </Grid>
                                  </Grid >
                                  <Grid item>
                                    <div className={classes.topScoreFont}>
                                      {this.state.topScore}
                                    </div>
                                  </Grid >
                                </Grid >
                              </Grid >
                            </Grid >
                          </Grid >
                          <Grid item xs={2} >
                            <Grid container justify="center" alignItems="center" >
                              <Grid item>
                                <Grid container justify="center" alignItems="center" >
                                  <Grid item>
                                    <Grid container className={classes.startButtonContainer} justify="center" alignItems="center" direction="column">
                                      <Grid item >
                                        <div ref={this.startRef}
                                          className={classes.startButtonFont}
                                          onClick={!this.state.gameOn ? (e) => { this.startGame(e) } : undefined} >
                                          <span className={this.state.flicker ? classes.textFlicker2s : null}>
                                            S<br />
                                          </span>
                                            T<br />
                                            A<br />
                                          <span className={this.state.flicker ? classes.textFlicker3s : null}>
                                            R<br />
                                          </span>
                                            T
                                        </div>
                                      </Grid >
                                    </Grid >
                                  </Grid >
                                </Grid >
                              </Grid >
                            </Grid >
                          </Grid>
                        </Grid >
                      </Grid >
                    </Grid >
                  </Grid >

                  {/* Bottom Row with Playing Buttons */}
                  <Grid item xs={this.state.bottomSection} style={{ height: this.state.bottomRowHeight }}>
                    <Grid container style={{ height: '100%' }}>
                      <Grid item xs={12} style={{ height: '50%' }}>
                        <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                          <Grid item className={classes.taBlock} style={{ width: '175px' }}>
                            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                              <Grid item>
                                <Grid container className={classes.blockCenter} justify="center" alignItems="center" direction="column" spacing={0}>
                                  <Grid item className={classes.taLightContainer}>
                                    <Button
                                      variant="outlined"
                                      color='primary'
                                      className={[classes.clickButton, classes.taButtonRadius].join(" ")}
                                      onClick={this.state.gameOn ? (e) => { this.tileClick(e, 1) } : undefined}>
                                    </Button>
                                    <div ref={this.taRef} className={classes.textStyleTa}>
                                      <span>t</span>a
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid >
                            </Grid>
                          </Grid>
                          <Grid item className={classes.mateBlock} style={{ width: '175px' }}>
                            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                              <Grid item>
                                <Grid container className={classes.blockCenter} justify="center" alignItems="center" direction="column" spacing={0}>
                                  <Grid item className={classes.mateLightContainer}>
                                    <Button
                                      variant="outlined"
                                      color='primary'
                                      className={[classes.clickButton, classes.mateButtonRadius].join(" ")}
                                      onClick={this.state.gameOn ? (e) => { this.tileClick(e, 2) } : undefined}>
                                    </Button>
                                    <div ref={this.mateRef} className={classes.textStyleMate}>
                                      <span>m</span>a<span>te</span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid >
                            </Grid>
                          </Grid >
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ height: '50%' }}>
                        <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                          <Grid item className={classes.tamateBlock} style={{ width: '350px' }}>
                            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                              <Grid item >
                                <Grid container className={classes.blockCenter} justify="center" alignItems="center" direction="column" spacing={0}>
                                  <Grid item className={classes.tamateLightContainer}>
                                    <Button
                                      variant="outlined"
                                      color='primary'
                                      className={[classes.clickButton, classes.taButtonRadius].join(" ")}
                                      onClick={this.state.gameOn ? (e) => { this.tileClick(e, 3) } : undefined}>
                                    </Button>
                                    <div ref={this.tamateRef} className={classes.textStyleTamate}>
                                      <span>ta</span>m<span>a</span>te
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid >
                            </Grid>
                          </Grid >
                        </Grid>
                      </Grid>
                    </Grid >
                  </Grid >
                </Grid >
              </Grid >

              {/* Right Section for Ads */}
              <Hidden mdDown>
                <Grid item xs={false} lg={4} style={{ padding: 0 }}></Grid>
              </Hidden>
            </Grid >
          </Grid >
        </Grid >
      </div >
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(App);